import { Component, input, InputSignal, output } from '@angular/core';

@Component({
  selector: 'amm-view-more',
  standalone: true,
  imports: [],
  templateUrl: './view-more.component.html',
  styleUrl: './view-more.component.scss',
})
export class ViewMoreComponent {
  public isSucccess: InputSignal<boolean> = input(true);
  public successBtnText: InputSignal<string> = input('View more');
  public errorIconName: InputSignal<string> = input('error');
  public errorMsg: InputSignal<string> = input(
    'Loading failed. Please try again.',
  );
  public errorBtnText: InputSignal<string> = input('Load again');

  public sucessBtnClick = output();
  public errorBtnClick = output();
  onSuccessBtnClick() {
    this.sucessBtnClick.emit();
  }
  onErrorBtnClick() {
    this.errorBtnClick.emit();
  }
}
