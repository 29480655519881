@if (isSucccess()) {
  <div
    class="flex flex-row justify-center cursor-pointer view-more-btn-wrapper"
    (click)="onSuccessBtnClick()"
  >
    <div class="view-more-btn">
      <span>{{ successBtnText() }}</span>
    </div>
  </div>
} @else {
  <div class="flex flex-col">
    <div class="flex flex-row gap-2 justify-center">
      <span class="material-symbols-outlined load-error">
        {{ errorIconName() }}</span
      >
      <span class="fail-msg">Loading failed. Please try again.</span>
    </div>
    <div
      class="flex flex-row justify-center cursor-pointer view-more-btn-wrapper"
      (click)="onErrorBtnClick()"
    >
      <div class="view-more-btn">
        <span>{{ errorBtnText() }}</span>
      </div>
    </div>
  </div>
}
